import { Text } from '@audi/audi-ui-react';
import { UeElement } from '@oneaudi/falcon-tools';

import React, { useContext } from 'react';
import styled from 'styled-components';
import { ContentContext } from '../context/ContentContext';
import { useFeatureAppEnv } from '../context/FeatureAppEnvContext';
import { checkLinkTarget } from '../utils/trackingUtils';
import { providePlaceHolderText } from '../utils/uEPlaceholder';

const HeaderContainer = styled.div`
  text-align: center;
  padding-block: var(${(props) => props.theme.responsive.spacing.xxxl})
    var(${(props) => props.theme.responsive.spacing.xxl});
  padding-inline: var(${(props) => props.theme.responsive.spacing.pageMargin});

  > h2 {
    color: black !important;
  }
  > p {
    color: black !important;
  }
`;

export const Header = () => {
  const content = useContext(ContentContext);
  const env = useFeatureAppEnv();
  const trackingService = env.featureServices['audi-tracking-service'];
  const renderModeService = env.featureServices['audi-render-mode-service'];

  if (!content) {
    return null;
  }

  return (
    <HeaderContainer>
      <Text variant="order2" as="h2">
        <UeElement type="text" property="headline" label="Headline">
          {providePlaceHolderText(content.headline, 'Headline Placeholder', renderModeService)}
        </UeElement>
      </Text>
      <Text as="p" variant="copy2" spaceStackStart="s">
        <UeElement type="text" property="link_text" label="Link text">
          <a
            href={content.link_url}
            onClick={() => {
              trackingService?.track({
                event: {
                  eventInfo: {
                    eventAction: checkLinkTarget(content.link_url),
                    eventName: "hero carline slider - click on 'view all models'",
                  },
                  attributes: {
                    componentName: 'hero-carline-slider',
                    label: content.link_text,
                    targetURL: new URL(content.link_url, window.location.origin).toString(),
                    clickID: '',
                    elementName: 'text link',
                    value: content.headline,
                    pos: '',
                  },
                },
              });
            }}
          >
            {providePlaceHolderText(content.link_text, 'Link Placeholder', renderModeService)}
          </a>
        </UeElement>
      </Text>
    </HeaderContainer>
  );
};
