import { Text } from '@audi/audi-ui-react';
import { useInViewEffect } from '@oneaudi/feature-app-utils';
import { UeElement } from '@oneaudi/falcon-tools';
import type { FC } from 'react';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { CarlineBlock } from './components/CarlineBlock';
import { ConsumptionAndEmission } from './components/ConsumptionAndEmission';
import { Header } from './components/Header';
import { NavigationButton } from './components/button/NavigationButton';
import { NavigationButtonGroup } from './components/button/NavigationButtonGroup';
import { ImageSlider } from './components/slideshow/ImageSlider';
import { ContentContext } from './context/ContentContext';
import { CurrentCarlineContext } from './context/CurrentVehicleContext';
import { useFeatureAppEnv } from './context/FeatureAppEnvContext';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const ImageOverlay = styled.div`
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  overflow: hidden;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr max-content max-content;
`;

export const FeatureApp: FC = () => {
  const env = useFeatureAppEnv();
  const content = useContext(ContentContext);
  const [visibleVehicleIndex, setVisibleVehicleIndex] = useState(0);
  const trackingService = env.featureServices['audi-tracking-service'];
  const updateVisibleVehicleIndex = useCallback(
    (action: 'click' | 'slide', index: number) => {
      setVisibleVehicleIndex((currentIndex) => {
        if (currentIndex !== index)
          trackingService?.track({
            event: {
              eventInfo: {
                eventAction: 'image',
                eventName: 'hero carline slider - change image',
              },
              attributes: {
                componentName: 'hero-carline-slider',
                label: index > currentIndex ? 'forward' : 'backward',
                targetURL: '',
                clickID: content.carlines[index],
                elementName: action === 'slide' ? 'image' : 'arrow',
                value: content.headline,
                pos: `${currentIndex + 1}~${index + 1}`,
              },
            },
          });
        return index;
      });
    },
    [setVisibleVehicleIndex],
  );

  const currentCarline = useMemo(
    () => ({ index: visibleVehicleIndex, id: content.carlines[visibleVehicleIndex] }),
    [visibleVehicleIndex, content],
  );

  useEffect(() => {
    trackingService?.track({
      componentUpdate: {
        componentInfo: {
          componentID: env.featureAppId,
        },
        attributes: {
          implementer: 2,
          version: __FEATURE_APP_VERSION__,
        },
      },
      event: {
        eventInfo: {
          eventAction: 'feature_app_ready',
          eventName: 'hero carline slider - feature app ready',
        },
        attributes: {
          componentName: 'hero-carline-slider',
          label: '',
          targetURL: '',
          clickID: '',
          elementName: '',
          value: '',
          pos: '',
        },
      },
    });
  }, []);

  useInViewEffect(() => {
    trackingService?.track({
      event: {
        eventInfo: {
          eventAction: 'impression',
          eventName: 'hero carline slider - impression',
        },
        attributes: {
          componentName: 'hero-carline-slider',
          label: '',
          targetURL: '',
          clickID: currentCarline.id,
          elementName: '',
          value: content.headline,
          pos: '',
        },
      },
    });
  });

  return (
    <CurrentCarlineContext.Provider value={currentCarline}>
      <Header />
      <Container>
        <ImageSlider
          visibleVehicleIndex={visibleVehicleIndex}
          setVisibleVehicleIndex={(index: number) => {
            updateVisibleVehicleIndex('slide', index);
          }}
        />
        <ImageOverlay>
          <NavigationButtonGroup>
            <NavigationButton
              direction="back"
              onClick={() => {
                updateVisibleVehicleIndex('click', visibleVehicleIndex - 1);
              }}
              visible={visibleVehicleIndex > 0}
              disabled={visibleVehicleIndex === 0}
            />
            <NavigationButton
              direction="forward"
              onClick={() => {
                updateVisibleVehicleIndex('click', visibleVehicleIndex + 1);
              }}
              visible={visibleVehicleIndex < content.carlines.length - 1}
              disabled={visibleVehicleIndex === content.carlines.length - 1}
            />
          </NavigationButtonGroup>
          <CarlineBlock />
        </ImageOverlay>
      </Container>
      {content.caeDisplayOption_showCae && <ConsumptionAndEmission />}
      {content.disclaimer && (
        <Text variant="copy2" tint="secondary" spacing={['m', 'pageMargin']}>
          <UeElement type="text" property="disclaimer" label="Disclaimer">
            {content.disclaimer}
          </UeElement>
        </Text>
      )}
    </CurrentCarlineContext.Provider>
  );
};
