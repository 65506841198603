import type { RenderModeServiceV1 } from '@oneaudi/render-mode-service';
import { RenderMode } from '@oneaudi/render-mode-service';

/**
 * If a field is has no content but we are in a the RenderMode.EDIT
 * Then we set a placeholder text to make the editing in the UE possible.
 * Otherwise the element may not be shown, which makes it impossible to edit it in the
 * UE
 *
 * @param field
 * @param placeholder
 * @param renderModeService
 * @returns
 */
export function providePlaceHolderText(
  field: string,
  placeholder: string,
  renderModeService?: RenderModeServiceV1,
) {
  if (!field && renderModeService && renderModeService.getRenderMode() === RenderMode.EDIT) {
    return placeholder;
  }

  return field;
}
