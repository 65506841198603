import { createContext } from 'react';

export interface CurrentCarlineContextProps {
  readonly index: number;
  readonly id: string;
}

export const CurrentCarlineContext = createContext<CurrentCarlineContextProps>({
  index: 0,
  id: '',
});
