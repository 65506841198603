(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("@audi/audi-ui-react-v2"), require("@oneaudi/onegraph-client"), require("styled-components"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "@audi/audi-ui-react-v2", "@oneaudi/onegraph-client", "styled-components"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("react"), require("@audi/audi-ui-react-v2"), require("@oneaudi/onegraph-client"), require("styled-components")) : factory(root["react"], root["@audi/audi-ui-react-v2"], root["@oneaudi/onegraph-client"], root["styled-components"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(this, (__WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE__audi_audi_ui_react__, __WEBPACK_EXTERNAL_MODULE__oneaudi_onegraph_client__, __WEBPACK_EXTERNAL_MODULE_styled_components__) => {
return 