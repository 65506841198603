import type { FetchI18nOptions, I18nTexts } from './fetchI18n';
import { fetchI18n } from './fetchI18n';

export interface CreateUniversalStateOptions extends FetchI18nOptions {}

export interface UniversalState {
  readonly i18nTexts: I18nTexts;
}

export async function createUniversalState(
  options: CreateUniversalStateOptions,
): Promise<UniversalState> {
  const i18nTexts = await fetchI18n(options);

  return { i18nTexts };
}
