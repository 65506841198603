import type { FC } from 'react';
import React, { useContext, useEffect, useRef } from 'react';
import styled, { useTheme } from 'styled-components';
import urlJoin from 'url-join';
import { ContentContext } from '../../context/ContentContext';
import { CarImageWrapper } from '../CarImageWrapper';
import { useFeatureAppEnv } from '../../context/FeatureAppEnvContext';
import { backgroundImageWidths } from '../../../img/config.mjs';

const ScrollContainer = styled.div`
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  height: 100%;
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  width: fit-content;

  // TODO remove padding on mobile?
  // padding needs to be capped as there is a max width of 1920. 20vw at 1920px is 384px
  padding: 0 min(20vw, 384px);
`;

const BackgroundImageWrapper = styled.div`
  position: absolute;
  overflow: hidden;
  left: 0;
  height: 100%;
  width: 100%;
`;

const BackgroundImage = styled.img`
  position: relative;
  height: 153%;
  top: -53%;
  width: auto;
  aspect-ratio: 7 / 2;

  @media (min-width: ${(props) => props.theme.breakpoints.s}px) {
    height: 180%;
    top: -65%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.s + 106}px) {
    top: -59%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    height: 267%;
    top: -110%;
  }
`;

export interface ImageSliderProps {
  readonly visibleVehicleIndex: number;
  readonly setVisibleVehicleIndex: (index: number) => void;
}

export const ImageSlider: FC<ImageSliderProps> = ({
  visibleVehicleIndex: targetVisibleVehicleIndex,
  setVisibleVehicleIndex,
}) => {
  const content = useContext(ContentContext);
  const { baseUrl } = useFeatureAppEnv();
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const itemsRef = useRef(Array<HTMLElement | null>(6));
  const visibleVehicleIndexRef = useRef(targetVisibleVehicleIndex);
  const { breakpoints } = useTheme();

  useEffect(() => {
    const scrollContainerElement = scrollContainerRef.current;

    if (!scrollContainerElement) {
      return;
    }

    if (visibleVehicleIndexRef.current !== targetVisibleVehicleIndex) {
      // Vehicle index was changed from the outside without scrolling to the
      // vehicle (e.g. due to navigation button clicks), so we need to scroll to
      // the vehicle.
      const vehicleElement = itemsRef.current[targetVisibleVehicleIndex];

      if (!vehicleElement) {
        return;
      }

      const scrollLeft =
        vehicleElement.offsetLeft -
        // Subtract half of the difference in widths because the vehicle is centered.
        (scrollContainerElement.offsetWidth - vehicleElement.offsetWidth) / 2;

      scrollContainerElement.scrollTo({ behavior: 'smooth', left: scrollLeft });
    }
  }, [targetVisibleVehicleIndex]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const activeElement = entries.find(
          (entry): entry is IntersectionObserverEntry & { target: HTMLElement } =>
            entry.isIntersecting && entry.target instanceof HTMLElement,
        )?.target;

        if (activeElement) {
          visibleVehicleIndexRef.current = itemsRef.current.indexOf(activeElement);
          setVisibleVehicleIndex(visibleVehicleIndexRef.current);
        }
      },
      { threshold: 0.99, rootMargin: '100% 0px 100% 0px' },
    );

    itemsRef.current.forEach((element) => {
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const BackgroundOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 35%, rgba(0, 0, 0, 0.8) 100%);
    pointer-events: none;
  `;

  return (
    <ScrollContainer ref={scrollContainerRef}>
      <ImageContainer>
        <BackgroundImageWrapper>
          <BackgroundImage
            src={urlJoin(baseUrl, '/static/img/background-2000w.jpg')}
            srcSet={backgroundImageWidths
              .map((width) => {
                const url = urlJoin(baseUrl, `/static/img/background-${width}w.jpg`);

                return `${url} ${width}w`;
              })
              .join(', ')}
            sizes={[
              // CarImageWrapper__Wrapper: width 52vw, aspect-ratio 19 / 20 => height 54.7vw
              // ImageSlider__BackgroundImage: height 267% or 146.2vw
              // BackgroundImage: aspect-ratio 5 / 2 => width 365.4vw
              `(min-width: ${breakpoints.l}px) 365.4vw`,
              // CarImageWrapper__Wrapper: width 60vw, aspect-ratio 5 / 6 => height 72vw
              // ImageSlider__BackgroundImage: height 267% or 192.24vw
              // BackgroundImage: aspect-ratio 5 / 2 => width 480.6vw
              `(min-width: ${breakpoints.m}px) 480.6vw`,
              // CarImageWrapper__Wrapper: width 60vw, aspect-ratio 2 / 3 => height 90vw
              // ImageSlider__BackgroundImage: height 178% or 160.2vw
              // BackgroundImage: aspect-ratio 5 / 2 => width 400.5vw
              `(min-width: ${breakpoints.s}px) 400.5vw`,
              // CarImageWrapper__Wrapper: min-width 300px, aspect-ratio 4 / 7 => height 525px
              // ImageSlider__BackgroundImage: height 144% or 756px
              // BackgroundImage: aspect-ratio 5 / 2 => width 1890px
              '1890px',
            ].join(', ')}
            loading="lazy"
            alt=""
          />
        </BackgroundImageWrapper>
        {/* here */}
        {content?.carlines.map((id, index) => (
          <CarImageWrapper
            key={id}
            carlineId={id}
            ref={(el) => {
              itemsRef.current[index] = el;
            }}
          />
        ))}
        <BackgroundOverlay />
      </ImageContainer>
    </ScrollContainer>
  );
};
