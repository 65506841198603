import { Text } from '@audi/audi-ui-react';
import {
  cleanupEmissionConsumptionMarkup,
  renderTextWithFootnotesReferencesV2,
} from '@oneaudi/feature-app-utils';
import { useQuery } from '@oneaudi/onegraph-client';
import React, { useContext } from 'react';
import type {
  CarlineQuery,
  CarlineQueryVariables,
} from '../../graphql/documents/carline.generated';
import { CarlineDocument } from '../../graphql/documents/carline.generated';
import { CurrentCarlineContext } from '../context/CurrentVehicleContext';
import { useFeatureAppEnv } from '../context/FeatureAppEnvContext';

export const ConsumptionAndEmission = () => {
  const { id } = useContext(CurrentCarlineContext);
  const { featureServices } = useFeatureAppEnv();
  const { countryCode, language } = featureServices['gfa:locale-service'];
  const { data } = useQuery<CarlineQuery, CarlineQueryVariables>(CarlineDocument, {
    variables: {
      identifier: {
        brand: 'A',
        id,
        country: countryCode,
        language,
      },
    },
  });
  const summary = data?.carline.consumptionAndEmission.summary;

  if (!summary) {
    return null;
  }

  return (
    <Text variant="copy2" tint="secondary" spacing={['m', 'pageMargin']}>
      {renderTextWithFootnotesReferencesV2(cleanupEmissionConsumptionMarkup(summary.consumption))}
      {'; '}
      {renderTextWithFootnotesReferencesV2(cleanupEmissionConsumptionMarkup(summary.emissionCO2))}
      {summary.co2Class && (
        <>
          {'; '}
          {renderTextWithFootnotesReferencesV2(cleanupEmissionConsumptionMarkup(summary.co2Class))}
        </>
      )}
      {summary.dischargedConsumption && (
        <>
          {'; '}
          {renderTextWithFootnotesReferencesV2(
            cleanupEmissionConsumptionMarkup(summary.dischargedConsumption),
          )}
        </>
      )}
      {summary.dischargedCO2Class && (
        <>
          {'; '}
          {renderTextWithFootnotesReferencesV2(
            cleanupEmissionConsumptionMarkup(summary.dischargedCO2Class),
          )}
        </>
      )}
    </Text>
  );
};
