import { Icon } from '@audi/audi-ui-react';
import React, { useContext, type FC } from 'react';
import styled from 'styled-components';
import { UniversalStateContext } from '../../context/UniversalStateContext';

type Direction = 'back' | 'forward';

const StyledNavigationButton = styled.button<{ visible: boolean; direction: Direction }>`
  position: absolute;
  ${(props) => (props.direction === 'back' ? 'left: 0;' : 'right: 0;')}
  top: 26%;
  z-index: 10;
  appearance: none;
  background: white;
  border: none;
  padding: 8px;
  transform: ${({ direction }) => (direction === 'back' ? 'rotate(-180deg)' : 'rotate(0)')};
  pointer-events: auto;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};

  &:not(:disabled):hover {
    cursor: pointer;
  }

  & > svg {
    display: block;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.s}px) {
    top: 34%;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.s + 106}px) {
    position: relative;
    top: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    padding: 16px;
    margin-inline: 0;
    visibility: visible;
    background: ${({ visible }) => (visible ? 'white' : 'rgba(255, 255, 255, 0.1)')};
    & > svg {
      color: var(
        ${({ theme, disabled }) =>
          disabled ? theme.colors.interaction.tertiary.disabled : theme.colors.base.brand.black}
      );
    }
  }
`;

export interface NavigationButtonProps {
  readonly onClick: () => void;
  readonly direction: Direction;
  readonly disabled?: boolean;
  readonly visible?: boolean;
}

export const NavigationButton: FC<NavigationButtonProps> = ({
  onClick,
  direction,
  disabled = false,
  visible = true,
}) => {
  const universalState = useContext(UniversalStateContext);

  return (
    <StyledNavigationButton
      onClick={onClick}
      visible={visible}
      disabled={disabled}
      direction={direction}
      aria-label={
        direction === 'back'
          ? universalState?.i18nTexts.previousButtonText
          : universalState?.i18nTexts.nextButtonText
      }
    >
      <Icon name="forward" size="xs" variant="secondary" />
    </StyledNavigationButton>
  );
};
