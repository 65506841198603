import { Text } from '@audi/audi-ui-react';
import React, { useContext, useEffect, useRef, useState, type FC } from 'react';
import { useMediaQuery } from '@oneaudi/feature-app-utils';
import { UniversalStateContext } from '../context/UniversalStateContext';
import { useFeatureAppEnv } from '../context/FeatureAppEnvContext';
import type { CarlineQuery } from '../../graphql/documents/carline.generated';
import { ContentContext } from '../context/ContentContext';
import { CurrentCarlineContext } from '../context/CurrentVehicleContext';
import { checkLinkTarget } from '../utils/trackingUtils';
import {
  Flyout,
  FlyoutCloseButton,
  StyledText,
  StyledActionWrapper,
  StyledButtonWrapper,
  StyledButton,
  VTPWrapper,
  VTPButton,
} from './ActionWrapperStyles';

export interface ActionWrapperProps {
  readonly carlineQuery: CarlineQuery;
}

export const ActionWrapper: FC<ActionWrapperProps> = ({ carlineQuery: { carline } }) => {
  const { featureServices } = useFeatureAppEnv();
  const content = useContext(ContentContext);

  const numberFormatter = featureServices['audi-number-formatter-service'];
  const trackingService = featureServices['audi-tracking-service'];
  const universalState = useContext(UniversalStateContext);
  const [actionsFlyoutIsVisible, setActionsFlyoutIsVisible] = useState(false);
  const newCars = carline.availableStock?.newCars;
  const usedCars = carline.availableStock?.usedCars;
  const newCarsAmount = newCars?.amount ?? 0;
  const usedCarsAmount = usedCars?.amount ?? 0;
  let availableStock = false;
  const popoverRef = useRef<HTMLDivElement>(null);

  if (content?.vtpDisplayOption_showVTP && newCarsAmount + usedCarsAmount > 0) {
    availableStock = true;
  }
  const { index } = useContext(CurrentCarlineContext);

  if (!carline.links) {
    return null;
  }
  const wideScreen = useMediaQuery('(min-width: 768px)');

  const handleClickOutside = (event: MouseEvent | TouchEvent) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
      setActionsFlyoutIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const renderFlyout = () => (
    <Flyout actionsFlyoutIsVisible={actionsFlyoutIsVisible} ref={popoverRef}>
      <FlyoutCloseButton
        variant="icon-tertiary"
        size="small"
        icon="cancel"
        onClick={() => setActionsFlyoutIsVisible(!actionsFlyoutIsVisible)}
      />
      <Text variant="order4" spaceStackEnd="l">
        {carline.name}
      </Text>
      {newCarsAmount === 0 ? (
        <StyledText variant="copy2" spaceStackEnd="l">
          {newCarsLabel}
        </StyledText>
      ) : (
        <Text as="p" variant="copy2" spaceStackEnd="l">
          <a href={newCars!.url} onClick={() => trackLink('new cars', newCars!.url, newCarsLabel)}>
            {newCarsLabel}
          </a>
        </Text>
      )}
      {usedCarsAmount === 0 ? (
        <StyledText variant="copy2" spaceStackEnd="l">
          {usedCarsLabel}
        </StyledText>
      ) : (
        <Text as="p" variant="copy2">
          <a
            href={usedCars!.url}
            onClick={() => trackLink('used cars', usedCars!.url, usedCarsLabel)}
          >
            {usedCarsLabel}
          </a>
        </Text>
      )}
    </Flyout>
  );

  const clickID = content.carlines[index];
  const trackLink = (
    type: 'configure' | 'discover' | 'used cars' | 'new cars',
    targetURL: string,
    label?: string,
  ) => {
    trackingService?.track({
      event: {
        eventInfo: {
          eventAction: checkLinkTarget(targetURL),
          eventName: `hero carline slider - click on '${type}'`,
        },
        attributes: {
          componentName: 'hero-carline-slider',
          label,
          targetURL: new URL(targetURL, window.location.origin).toString(),
          clickID,
          elementName: 'button',
          value: content.headline,
          pos: `${index + 1}`,
        },
      },
    });
  };
  const newCarsLabel = universalState?.i18nTexts.vtpNewCarsLinkText.replace(
    // eslint-disable-next-line no-template-curly-in-string
    '${value}',
    numberFormatter.formatNumber(newCarsAmount),
  );
  const usedCarsLabel = universalState?.i18nTexts.vtpUsedCarsLinkText.replace(
    // eslint-disable-next-line no-template-curly-in-string
    '${value}',
    numberFormatter.formatNumber(usedCarsAmount),
  );
  const vtpLabel = universalState?.i18nTexts.vtpHeadline.replace(
    // eslint-disable-next-line no-template-curly-in-string
    '${value}',
    carline.name,
  );

  return (
    <StyledActionWrapper>
      {availableStock && !wideScreen && renderFlyout()}
      <StyledButtonWrapper
        hasConfigureLink={!!carline.links?.configuratorPage}
        hasVTPButton={availableStock}
      >
        {carline.links?.configuratorPage?.url && (
          <StyledButton
            variant="primary"
            size="small"
            href={carline.links.configuratorPage.url}
            className="configureBtn"
            onClick={() =>
              trackLink(
                'configure',
                carline.links!.configuratorPage!.url!,
                universalState?.i18nTexts.configureLinkText,
              )
            }
          >
            {universalState?.i18nTexts.configureLinkText}
          </StyledButton>
        )}
        <StyledButton
          variant="secondary"
          size="small"
          href={carline.links.carlineStartPage!.url!}
          className="discoverBtn"
          onClick={() =>
            trackLink(
              'discover',
              carline.links!.carlineStartPage!.url!,
              universalState?.i18nTexts.discoverLinkText,
            )
          }
        >
          {universalState?.i18nTexts.discoverLinkText}
        </StyledButton>
        {availableStock && (
          <VTPWrapper>
            {wideScreen && renderFlyout()}
            <VTPButton
              variant="text"
              size="small"
              icon="car-search"
              onClick={() => {
                if (actionsFlyoutIsVisible === false) {
                  trackingService?.track({
                    event: {
                      eventInfo: {
                        eventAction: 'content',
                        eventName: 'hero carline slider - click to open actions menu',
                      },
                      attributes: {
                        componentName: 'hero-carline-slider',
                        label: `${vtpLabel} (${numberFormatter.formatNumber(newCarsAmount + usedCarsAmount)})`,
                        targetURL: '',
                        clickID,
                        elementName: 'icon',
                        value: content.headline,
                        pos: `${index + 1}`,
                      },
                    },
                  });
                }
                setActionsFlyoutIsVisible(!actionsFlyoutIsVisible);
              }}
              flyoutOpen={actionsFlyoutIsVisible}
            >
              {vtpLabel} ({numberFormatter.formatNumber(newCarsAmount + usedCarsAmount)})
            </VTPButton>
          </VTPWrapper>
        )}
      </StyledButtonWrapper>
    </StyledActionWrapper>
  );
};
