import type { FeatureAppEnvironment } from '@feature-hub/core';
import { createContext, useContext } from 'react';
import type { FeatureServiceDependencies } from '../FeatureHubAppDefinition';

export interface FeatureAppEnvContextValue
  extends Omit<FeatureAppEnvironment<FeatureServiceDependencies, void>, 'baseUrl'> {
  readonly baseUrl: string;
}

export const FeatureAppEnvContext = createContext<FeatureAppEnvContextValue | undefined>(undefined);

export function useFeatureAppEnv(): FeatureAppEnvContextValue {
  const context = useContext(FeatureAppEnvContext);
  if (!context) {
    throw new Error(
      'The context was not defined. Did you forget to wrap the feature app in <FeatureAppEnvContext.Provider>?',
    );
  }
  return context;
}
