import React from 'react';
import type { FC, ReactNode } from 'react';
import styled from 'styled-components';

const StyledButtonGroup = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-area: 1 / 1 / 4 / 2;
`;

export interface NavigationButtonGroupProps {
  readonly children?: ReactNode;
}

export const NavigationButtonGroup: FC<NavigationButtonGroupProps> = ({ children }) => (
  <StyledButtonGroup>{children}</StyledButtonGroup>
);
